







import { type PropType, defineComponent } from "@vue/composition-api";
import { mapActions, mapState } from "vuex";
import Loader from "@/components/elements/Loader.vue";
import { logger } from "@/logger";

export default defineComponent({
	components: {
		Loader,
	},
	props: {
		type: {
			type: String,
			default: "",
		},
		mod: {
			type: String as PropType<AutocompleteOptions<"compare">>,
			default: "compare",
		},
	},
	data() {
		return {
			result: { waarde: 0 },
			loading: false,
		};
	},
	computed: {
		...mapState("statistieken", ["query"]),
	},
	watch: {
		query: {
			deep: true,
			immediate: true,
			handler() {
				this.load();
			},
		},
	},
	methods: {
		...mapActions("statistieken", ["getByPeriod", "getCompareByPeriod"]),
		async load() {
			this.loading = true;
			await this[this.mod === "compare" ? "getCompareByPeriod" : "getByPeriod"]({
				...this.query,
				soort: this.type,
			})
				.then((result: TODO) => {
					this.result = result[this.mod === "compare" ? "overigeVestigingen" : "huidige"];
				})
				.catch(logger.log);
			this.loading = false;
		},
	},
});
