<template>
	<div class="pg_content_container">
		<div class="pg_content_1-1">
			<header class="pg_header">
				<div class="pg_header_heading">
					<h2>Overzicht van acties voor bezoekers</h2>

					<!-- <infoDropdown>
                        Vestibulum id ligula porta felis euismod semper.
          </infoDropdown>-->
				</div>
				<div class="pg_header_description">
					<p>
						Een totaaloverzicht van de acties voor mensen die in de periode in de sportschool zijn
						geweest
					</p>
				</div>
			</header>

			<div class="pg_grid" data-s-amount="3">
				<div class="pg_grid_item" data-s-amount-item>
					<div class="pg_grid_item_content">
						<div class="pg_grid_item_number">1.152</div>
					</div>
					<div class="pg_grid_item_title">Bezoekers met een openstaande actie</div>
				</div>
				<div class="pg_grid_item" data-s-amount-item>
					<div class="pg_grid_item_content">
						<div class="pg_grid_item_number">2.403</div>
					</div>
					<div class="pg_grid_item_title">Totaal aantal openstaande acties</div>
				</div>
				<div class="pg_grid_item" data-s-amount-item>
					<div class="pg_grid_item_content">
						<div class="pg_grid_item_number">
							<kpi-value type="aantal-acties" mod="value"></kpi-value>
						</div>
					</div>
					<div class="pg_grid_item_title">Uitgevoerde acties</div>
				</div>
			</div>

			<div class="pg_grid a_margin-top-40 a_margin-bottom-20" data-s-amount="3">
				<button
					:class="[selectedAction == 'actie-aantal-intakes' ? 's_is-active' : '']"
					class="pg_grid_item"
					data-s-amount-item
					@click="selectedAction = 'actie-aantal-intakes'"
				>
					<div class="pg_grid_item_heading">
						<div class="pg_grid_item_title s_elem-has-icon-left a_fill_default">
							Intakes
							<icon :icon-id="'icon_intake'"></icon>
						</div>
					</div>
					<div class="pg_grid_item_content">
						<div class="pg_grid_item_content_left">
							<div class="pg_grid_item_number">
								<kpi-value type="actie-aantal-intakes" mod="value"></kpi-value>
							</div>
						</div>
						<div class="pg_grid_item_content_right">
							<div class="pg_grid_item_description">Afgenomen intakes</div>
						</div>
					</div>
				</button>
				<button
					:class="[selectedAction == 'actie-aantal-metingen' ? 's_is-active' : '']"
					class="pg_grid_item"
					data-s-amount-item
					@click="selectedAction = 'actie-aantal-metingen'"
				>
					<div class="pg_grid_item_heading">
						<div class="pg_grid_item_title s_elem-has-icon-left a_fill_default">
							Metingen
							<icon :icon-id="'icon_meting'"></icon>
						</div>
					</div>
					<div class="pg_grid_item_content">
						<div class="pg_grid_item_content_left">
							<div class="pg_grid_item_number">
								<kpi-value type="actie-aantal-metingen" mod="value"></kpi-value>
							</div>
						</div>
						<div class="pg_grid_item_content_right">
							<div class="pg_grid_item_description">Leden zijn benaderd voor een meting</div>
						</div>
					</div>
				</button>
				<button
					:class="[selectedAction == 'actie-aantal-doelen' ? 's_is-active' : '']"
					class="pg_grid_item"
					data-s-amount-item
					@click="selectedAction = 'actie-aantal-doelen'"
				>
					<div class="pg_grid_item_heading">
						<div class="pg_grid_item_title s_elem-has-icon-left a_fill_default">
							Doelen
							<icon :icon-id="'icon_goal'"></icon>
						</div>
					</div>
					<div class="pg_grid_item_content">
						<div class="pg_grid_item_content_left">
							<div class="pg_grid_item_number">
								<kpi-value type="actie-aantal-doelen" mod="value"></kpi-value>
							</div>
						</div>
						<div class="pg_grid_item_content_right">
							<div class="pg_grid_item_description">
								Gemiddeld afgevallen gewicht onder alle leden
							</div>
						</div>
					</div>
				</button>
				<button
					:class="[selectedAction == 'actie-aantal-schemas' ? 's_is-active' : '']"
					class="pg_grid_item"
					data-s-amount-item
					@click="selectedAction = 'actie-aantal-schemas'"
				>
					<div class="pg_grid_item_heading">
						<div class="pg_grid_item_title s_elem-has-icon-left a_fill_default">
							Trainingsschema’s
							<icon :icon-id="'icon_trainingsschema'"></icon>
						</div>
					</div>
					<div class="pg_grid_item_content">
						<div class="pg_grid_item_content_left">
							<div class="pg_grid_item_number">
								<kpi-value type="actie-aantal-schemas" mod="value"></kpi-value>
							</div>
						</div>
						<div class="pg_grid_item_content_right">
							<div class="pg_grid_item_description">
								Leden zijn benaderd voor een trainingsschema
							</div>
						</div>
					</div>
				</button>
				<button
					:class="[selectedAction == 'actie-aantal-les-inschrijvingen' ? 's_is-active' : '']"
					class="pg_grid_item"
					data-s-amount-item
					@click="selectedAction = 'actie-aantal-les-inschrijvingen'"
				>
					<div class="pg_grid_item_heading">
						<div class="pg_grid_item_title s_elem-has-icon-left a_fill_default">
							Groepslessen
							<icon :icon-id="'icon_groepsles'"></icon>
						</div>
					</div>
					<div class="pg_grid_item_content">
						<div class="pg_grid_item_content_left">
							<div class="pg_grid_item_number">
								<kpi-value type="actie-aantal-les-inschrijvingen" mod="value"></kpi-value>
							</div>
						</div>
						<div class="pg_grid_item_content_right">
							<div class="pg_grid_item_description">Leden zijn benaderd voor groepslessen</div>
						</div>
					</div>
				</button>
				<button
					:class="[selectedAction == 'actie-aantal-welkomstformulieren' ? 's_is-active' : '']"
					class="pg_grid_item"
					data-s-amount-item
					@click="selectedAction = 'actie-aantal-welkomstformulieren'"
				>
					<div class="pg_grid_item_heading">
						<div class="pg_grid_item_title s_elem-has-icon-left a_fill_default">
							ProFit Gym leden app
							<icon :icon-id="'icon_app'"></icon>
						</div>
					</div>
					<div class="pg_grid_item_content">
						<div class="pg_grid_item_content_left">
							<div class="pg_grid_item_number">
								<kpi-value type="actie-aantal-welkomstformulieren" mod="value"></kpi-value>
							</div>
						</div>
						<div class="pg_grid_item_content_right">
							<div class="pg_grid_item_description">
								Leden zijn benaderd om de app te downloaden
							</div>
						</div>
					</div>
				</button>
				<!-- Physiotherapist referrals -->
				<button
					:class="[selectedAction == 'actie-aantal-fysio-verwijzingen' ? 's_is-active' : '']"
					class="pg_grid_item s_no-graph"
					data-s-amount-item
					@click="selectedAction = 'actie-aantal-fysio-verwijzingen'"
				>
					<div class="pg_grid_item_heading">
						<div class="pg_grid_item_title s_elem-has-icon-left a_fill_default">
							Doorverwijzingen fysio
							<icon :icon-id="'icon_kpi'"></icon>
						</div>
					</div>
					<div class="pg_grid_item_content">
						<div class="pg_grid_item_content_left">
							<div class="pg_grid_item_number">
								<kpi-value type="actie-aantal-fysio-verwijzingen" mod="value"></kpi-value>
							</div>
						</div>
						<div class="pg_grid_item_content_right">
							<div class="pg_grid_item_description">Leden zijn doorverwezen naar de fysio</div>
						</div>
					</div>
				</button>
				<!-- End physiotherapist referrals -->
			</div>
			<highcharts :options="chartOptions"></highcharts>
		</div>
		<loader :loading="loading"></loader>
	</div>
</template>

<script>
import _ from "lodash";
import { mapActions, mapState } from "vuex";
import KpiValue from "@/components/elements/KpiValue.vue";
import Loader from "@/components/elements/Loader.vue";
import { logger } from "@/logger";

export default {
	name: "Leden",
	components: {
		Loader,
		KpiValue,
	},
	data() {
		return {
			loading: false,
			selectedAction: "actie-aantal-intakes",
			chartOptions: {
				chart: {
					marginTop: 60,
					type: "area",
				},
				xAxis: {
					title: {
						text: "tijd",
					},
					type: "datetime",
					labels: {
						align: "center",
						x: 40,
					},
					startOnTick: true,
					showFirstLabel: true,
					dateTimeLabelFormats: {
						day: "%A",
					},
					tickInterval: 24 * 3600 * 1000,
				},
				yAxis: {
					title: {
						text: "intakes",
					},
				},
				plotOptions: {
					series: {
						pointStart: Date.UTC(2017, 10, 1, 7),
						pointInterval: 24 * 3600 * 1000,
					},
				},
				tooltip: {
					xDateFormat: "%A",
				},
				series: [
					{
						name: "deze periode",
						data: [33, 46, 87],
						fillOpacity: 0.1,
					},
					{
						name: "vorige periode",
						data: [12, 35, 25, 26, 44, 26, 28],
						fillOpacity: 0,
					},
				],
			},
		};
	},
	computed: {
		...mapState("statistieken", ["query"]),
	},
	watch: {
		selectedAction() {
			this.load();
		},
		query: {
			deep: true,
			immediate: true,
			handler() {
				this.load();
			},
		},
	},
	methods: {
		...mapActions("statistieken", ["getGraphData"]),
		async load() {
			// CHECK-INS PER UUR
			try {
				this.loading = true;
				const result = await this.getGraphData({
					...this.query,
					soort: this.selectedAction,
					periode: "week",
					vergelijkMet: "vorige-periode",
					chartOptions: this.chartOptions,
				});
				this.chartOptions.series[0].data =
					result.huidige.length > 0
						? result.huidige.map((item) => item.waarde)
						: _.fill(Array(7), 0);
				this.chartOptions.series[1].data =
					result.vorige.length > 0 ? result.vorige.map((item) => item.waarde) : _.fill(Array(7), 0);
				this.loading = false;
			} catch (error) {
				logger.error(error);
				// If request is forbidden no graph data can be requested for the selected action
				if (error.response && error.response.status === 403) {
					this.chartOptions.series[0].data = [];
					this.chartOptions.series[1].data = [];
					this.loading = false;
				}
			}
		},
	},
};
</script>

<style lang="scss" scoped>
.pg_loader {
	width: 100%;
	height: 100%;
	position: absolute;
	top: calc(100% - 42px);
	left: calc(100% - 42px);
}
</style>
