<template>
	<div style="display: flex; flex-direction: column; gap: 2.5rem">
		<!-- <period></period> -->
		<section class="pg_content">
			<div class="pg_content_container">
				<div class="pg_content_1-1">
					<header class="pg_header">
						<div class="pg_header_heading">
							<h1 class="s_elem-has-icon-left a_fill_default">
								Coaches
								<icon :icon-id="'icon_coaches'"></icon>
							</h1>
						</div>
						<div class="pg_header_description">
							<p style="margin-bottom: 0">
								De belangrijkste indicatoren rondom de coaches van
								{{ selectedBranch.vestigingNaam }}
							</p>
						</div>
					</header>
				</div>
			</div>
		</section>

		<section class="pg_content">
			<div class="pg_content_container">
				<div class="pg_content_1-1">
					<header class="pg_header">
						<div class="pg_header_heading">
							<h2>Coaches, beoordelingen &amp; acties</h2>
						</div>
						<div class="pg_header_description">
							<p>
								Het aantal acties en gegeven groepslessen per trainer (op basis van week, maand,
								jaar)
							</p>
						</div>
					</header>
					<vue-scroll-table
						v-if="coachTableHeaders.length && coachTableRows.length"
						:has-scroll="true"
						:data="coachTableRows"
						:headers="coachTableHeaders"
						:limits="[10, 20, 30]"
						:classes="tableStylingClasses"
						:translations="{ limit: 'per pagina' }"
					></vue-scroll-table>
					<hr />
				</div>
			</div>
		</section>

		<section class="pg_content">
			<div class="pg_content_container">
				<div class="pg_content_1-1">
					<header class="pg_header">
						<div class="pg_header_heading">
							<h2>Algemeen overzicht</h2>
						</div>
						<div class="pg_header_description">
							<p>De belangrijkste KPI's van alle coaches</p>
						</div>
					</header>
					<div class="pg_kpis a_margin-top-40 a_margin-bottom-20" data-s-amount="4">
						<statistics-badge-rect
							title="Bezoekers"
							soort="aantal-unieke-bezoeken"
							:selected="selectedBadge == 'aantal-unieke-bezoeken'"
							@selected="selectedBadge = 'aantal-unieke-bezoeken'"
						></statistics-badge-rect>
						<statistics-badge-rect
							title="Acties"
							soort="aantal-acties"
							:selected="selectedBadge == 'aantal-acties'"
							@selected="selectedBadge = 'aantal-acties'"
						></statistics-badge-rect>
						<statistics-badge-rect
							title="Leden geholpen"
							soort="aantal-geholpen-leden"
							:selected="selectedBadge == 'aantal-geholpen-leden'"
							@selected="selectedBadge = 'aantal-geholpen-leden'"
						></statistics-badge-rect>
						<statistics-badge-rect
							title="Actie/bezoekers ratio"
							soort="acties-bezoekers-ratio"
							:selected="selectedBadge == 'acties-bezoekers-ratio'"
							:value-formatter="formatRatio"
							@selected="selectedBadge = 'acties-bezoekers-ratio'"
						></statistics-badge-rect>
					</div>
					<kpis-chart :type="selectedBadge"></kpis-chart>
					<hr />
				</div>
			</div>
		</section>

		<section class="pg_content a_margin-top-40">
			<coaches-actions></coaches-actions>
		</section>
	</div>
</template>

<script>
// import _ from 'lodash';
import VueScrollTable from "vue-scroll-table";
import { mapState } from "vuex";
import StatisticsBadgeRect from "@/components/elements/StatisticsBadgeRect.vue";
import clubAuth from "@/lib/club-auth";
import CoachesActions from "./partials/CoachesActions.vue";
import KpisChart from "./partials/KpisChart.vue";

export default {
	name: "Leden",
	components: {
		StatisticsBadgeRect,
		KpisChart,
		CoachesActions,
		VueScrollTable,
	},
	data() {
		return {
			selectedBadge: "aantal-unieke-bezoeken",
			coachesTableData: null,
			coachTableHeaders: [
				{
					text: "Naam",
					width: 280,
					sortable: true,
				},
				{
					text: "# afspraken gedraaid",
					sortable: true,
				},
				{
					text: "# afspraken ingepland",
					sortable: true,
				},
				{
					text: "# acties",
					sortable: true,
				},
				{
					text: "# metingen verricht",
					sortable: true,
				},
				{
					text: "# sporters gekoppeld",
					sortable: true,
				},
				{
					text: "# lessen",
					sortable: true,
				},
			],
			tableStylingClasses: {
				tableContainer: ["pg_table", "pg_table_vst", "pg_table_coaches"],
				pagination: {
					container: ["pg_pagination"],
					limit: {
						container: ["pg_pagination_filters"],
					},
					links: {
						container: ["pg_pagination_links"],
					},
				},
				scroll: {
					table: ["pg_table"],
				},
				sticky: {
					container: ["pg_table-overlay_vst"],
					table: ["pg_table-overlay"],
				},
				sortButtons: {
					button: [
						"button",
						"v_transparent_body",
						"v_is-icon",
						"v_icon-size-16",
						"a_no-margin",
						"a_no-padding",
					],
				},
			},
		};
	},
	computed: {
		...mapState("statistieken", ["query"]),
		...mapState("branches", { selectedBranch: "selected" }),
		coachTableRows() {
			if (this.coachesTableData) {
				return this.coachesTableData.map((coachData) => {
					return [
						coachData.naam,
						coachData.aantalAfsprakenGedraaid,
						coachData.aantalAfsprakenIngepland,
						coachData.aantalActies,
						coachData.aantalMetingenVerricht,
						coachData.aantalSportersGekoppeld,
						coachData.aantalLessen,
					];
				});
			} else return [];
		},
	},
	watch: {
		query: {
			deep: true,
			immediate: true,
			handler() {
				this.loadCoachData();
			},
		},
	},
	methods: {
		async loadCoachData() {
			const diffType = {
				dag: "days",
				week: "weeks",
				maand: "months",
				jaar: "years",
			}[this.query.periode];
			const date = this.query.opening
				.clone()
				.add(this.query.index, diffType)
				.startOf(diffType.slice(0, -1));
			const res = await clubAuth.http.get(
				`/coaches/tabel/${
					this.query.periodType === "variabel" ? this.query.periodType : this.query.periode
				}?datum=${date.format("DD-MM-YYYY")}&dagen=${this.query.days}`,
			);
			this.coachesTableData = res.data;
		},
		formatRatio(ratio) {
			const newRatio = ratio < 0 ? 0 : ratio;
			const rounded = Math.round((newRatio + Number.EPSILON) * 100) / 100;
			return `${rounded}%`;
		},
	},
};
</script>

<style lang="scss">
.vst_table-container {
	&:before {
		left: 280px !important;
	}

	&.pg_table_coaches {
		th:not(:first-child) {
			font-size: 0.75rem;
			padding: 0.5rem;
			min-width: 7rem;
			padding-inline-end: 1.25rem;
			text-align: center;
		}

		td:not(:first-child) {
			min-width: unset;
			padding: 0.5rem;
			text-align: center;
		}
	}
}
</style>
